import React from 'react'
import { Heading, Stack, Text } from '@chakra-ui/react'

import { SEOMetadata } from '@components'

const NotFoundPage = ({ ...rest }) => (
  <Stack
    justify={'center'}
    minH={'100vh'}
    py={10}
    spacing={8}
    textAlign={'center'}
    {...rest}
  >
    <SEOMetadata title="404: Not Found" />
    <Heading>
      <Text textStyle={'h1'}>404: Not Found</Text>
    </Heading>
    <Text fontSize="2xl" my={5}>
      You just hit a route that doesn&#39;t exist... the sadness.
    </Text>
  </Stack>
)

export default NotFoundPage
